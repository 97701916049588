<template>
    <div>
        <NotFound message="Ups... Seite nicht gefunden!"/>
        <slot name="footer"/>
    </div>
</template>

<script>
    import NotFound from '@/components/NotFound'

    export default {
        name: 'PageNotFound',

        components: {
            NotFound
        },

        metaInfo: {
            title: '● 404'
        }
    }
</script>
